import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { environment } from "../../environments/environment";
import { ICampaignAdmin } from "../../models/admin/campaigns";
import { Skeleton, Stack } from "@mui/material";
import { SingleBottle } from "../../components/SingleBottle";

export interface CampaignImagesProps {
  campaign_uuid: string;
  media_url: string;
}

const CampaignSearchResult: React.FC = () => {
  const { searchQuery } = useParams<{ searchQuery: string }>();
  const [campaigns, setCampaigns] = useState<ICampaignAdmin[] | null>(null);
  const [campaignImages, setCampaignImages] = useState<
    CampaignImagesProps[] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [ordersData, setOrdersData] = useState<{ [key: string]: number }>({});

  const fetchSearchResult = useCallback(async (query: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${environment.baseUrl}/campaign/search?q=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        const campaignImages = response.data.data.campaign_images;

        const extractedImages = Object.values(campaignImages)
          .flat()
          .filter((img: any) => img.media_url)
          .map((img: any) => ({
            campaign_uuid: img.campaign_uuid,
            media_url: img.media_url,
          }));

        setCampaignImages(extractedImages);
        setCampaigns(response.data.data?.campaigns || []);
      } else {
        setError("Failed to fetch search results");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred while fetching search results");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchQuery) {
      fetchSearchResult(searchQuery);
    }
  }, [searchQuery, fetchSearchResult]);

  if (!searchQuery) {
    return <div>No search query provided</div>;
  }

  return (
    <main className="web_padding_y web_padding_x">
      <h2 className="web_heading text-left md:text-center ">
        SEARCH RESULTS FOR &ldquo;{searchQuery}&rdquo;
      </h2>
      <div className="flex cont mt-5 md:mt-10">
        {isLoading && (
          <Stack spacing={1}>
            <Stack spacing={1} direction="row">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={400}
                  height={200}
                />
              ))}
            </Stack>
            <Stack spacing={1} direction="row">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index + 3}
                  variant="rounded"
                  width={400}
                  height={200}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {error && <div className="text-red-500">{error}</div>}

        {!isLoading && !error && campaigns && (
          <>
            {campaigns.length > 0 ? (
              <div className="grid gap-6 sm:[grid-template-columns:repeat(auto-fill,minmax(300px,_1fr))] lg:grid-cols-3">
                {campaigns.map((campaign: ICampaignAdmin) => {
                  const campaignImageUrl = campaignImages?.find(
                    (img) => img.campaign_uuid === campaign.uuid
                  )?.media_url;
                  return (
                    <SingleBottle
                      key={campaign.uuid}
                      campaign={campaign}
                      campaignImage={campaignImageUrl!}
                      currentOrders={ordersData[campaign.uuid]}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="border border-dashed text-center border-gray-400 mt-4 mx-auto max-w-[450px] rounded-lg px-10 py-20">
                No campaigns found
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default CampaignSearchResult;
