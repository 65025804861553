import { Box, Skeleton, Stack, Tab, Tabs } from "@mui/material";
  import { addScriptDefault } from "meta-pixel";
  import moment from "moment";
  import { useEffect, useRef, useState } from "react";
  import {
    FaChevronLeft,
    FaChevronRight,
    FaFacebookF,
  FaTimes,
    FaCheckCircle 
  } from "react-icons/fa";
  import { FaXTwitter } from "react-icons/fa6";
  import { FiShoppingCart } from "react-icons/fi";
  import { useDispatch } from "react-redux";
  import { useNavigate, useParams } from "react-router-dom";
  import Select from "react-select";
  import { CSSProperties } from "styled-components";
  import type { Swiper as SwiperType } from "swiper";
  import "swiper/css";
  import "swiper/css/navigation";
  import { Navigation } from "swiper/modules";
  import { Swiper, SwiperSlide } from "swiper/react";
  import TiktokPixel from "tiktok-pixel";
  import api from "../../api/api";
  import BarImg from "../../assets/images/bar.png";
  import { ItemCountInput } from "../../components/item-count-input";
  import MailchimpCampaginPopup from "../../components/MailchimpCampaginPopup";
  import { environment } from "../../environments/environment";
  import { ICampaignAdmin } from "../../models/admin/campaigns";
  import { setSingleCampaign } from "../../store/authentication/actions/actions";
  import { currencyFormat } from "../../utils/format-currency";
  import { CampaignSingle_1 } from "./campaign-single/campaign";
  import { CampaignSingle_Comments } from "./campaign-single/comments";
  import { CampaignSingle_Updates } from "./campaign-single/updates";
  import { toast } from "react-toastify";

  interface CampaignImagesProp {
    uuid: string;
    sort_id: number;
    media_url: string;
  }

  const CampaignSingleNew = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sizeError, setSizeError] = useState(false);
    const currencyView = "$";
    const [campaignImages, setCampaignImages] = useState<CampaignImagesProp[]>(
      []
    );
    const [campaignDetails, setCampaignDetails] = useState<ICampaignAdmin | null>(
      null
    );
    const [campaignBacked, setCampaignBacked] = useState<boolean>(false);
    const [current_orders, setCurrent_orders] = useState<number>(0);
    const [sizes, setSizes] = useState<any[]>([]);
    const [currentCarouselSlide, setCarouselSlide] = useState(0);
    const [selectedSize, setSelectedSize] = useState<any>(null);
    const [isComingSoonModalVisible, setIsComingSoonModalVisible] =
      useState(false);
    const [mailchimpPopupVisible, setMailchimpPopupVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    // This is for displaying the thumbnail clicked by the user
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [activeUuid, setActiveUuid] = useState<string | null>(null);
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const [isBuyingNow, setIsBuyingNow] = useState(false);
    // const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);
    const swiperRef = useRef<SwiperType | null>(null);
    const fbq = addScriptDefault();
    TiktokPixel.init(`${process.env.REACT_APP_TIKTOK_ID}`);
    const [showCartPopup, setShowCartPopup] = useState(false);
    const [addedCartItem, setAddedCartItem] = useState<{
      image_url: string;
      name: string;
      quantity: number;
      size: string;
    } | null>(null);

    const carouselArrowStyles: CSSProperties = {
      position: "absolute",
      zIndex: 2,
      top: "calc(50% - 15px)",
      width: 30,
      height: 30,
      cursor: "pointer",
      background: "rgba(0, 0, 0, 0.5)",
      color: "#fff",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    // Function to get the index of the image by its UUID
    const getIndexByUuid = (uuid: string) => {
      return campaignImages.findIndex((img) => img.uuid === uuid);
    };

    // Function to display the thumbnail the user clicked
    const handleThumbnailClick = (uuid: string) => {
      setActiveUuid(uuid);
      const index = getIndexByUuid(uuid);
      if (swiperRef.current && index !== -1) {
        swiperRef.current.slideToLoop(index);
      }
    };

    const fetchCampaignDetails = async () => {
      try {
        const response = await api.get(
          `${environment.baseUrl}/campaign/fetch/single/${id}`
        );

        const today = moment();
        let endsIn = 0;
        // Check if the deadline has passed
        const campaign = response.data.data?.campaign;

        if (today.isAfter(campaign.campaign_deadline)) {
          endsIn = 0;
        } else {
          // Calculate the number of days ahead
          endsIn = Math.abs(today.diff(campaign.campaign_deadline, "days"));
        }

        // console.log("response data", response);

        const apiResponseImages = response.data.data.campaign_images;
        const extractedImages = apiResponseImages.map((item: any) => {
          return {
            media_url: item.media_url,
            sort_id: item.sort_id,
            uuid: item.uuid,
          };
        });

        const sortedImages = extractedImages.sort(
          (a: CampaignImagesProp, b: CampaignImagesProp) => a.sort_id - b.sort_id
        );

        setCampaignImages(sortedImages);

        setCampaignDetails({ ...response.data.data?.campaign, endsIn });
        formatSizeOptions({ ...response.data.data?.campaign, endsIn });
        setCampaignBacked(response.data.data?.campaign_backed ?? false);
        setCurrent_orders(response.data.data?.current_orders ?? 0);
        dispatch(setSingleCampaign(response.data.data?.campaign));
      } catch {
        navigate("/campaigns");
      } finally {
        //
      }
    };

    const formatSizeOptions = (campaign: ICampaignAdmin) => {
      const options: any[] = [];

      if (campaign.size_men_shoe) {
        options.push({
          label: "Men Shoe",
          options: campaign.size_men_shoe
            .split(",")
            .map((x: any) => ({ value: x, label: x })),
        });
      }

      if (campaign.size_women_shoe) {
        options.push({
          label: "Women Shoe",
          options: campaign.size_women_shoe
            .split(",")
            .map((x: any) => ({ value: x, label: x })),
        });
      }

      setSizes(options);
    };

    useEffect(() => {
      fetchCampaignDetails();
    }, []);

    const [itemCount, setItemCount] = useState(1);

    // Function to handle value change in the parent component
    const handleItemCountChange = (newValue: number) => {
      setItemCount(newValue);
    };

    const formatBarWidth = () => {
      const valueCalculated =
        (current_orders * 100) / (campaignDetails?.end_goal_order_number ?? 0);
      if (valueCalculated < 50) {
        return valueCalculated + "%";
      } else if (valueCalculated <= 100) {
        return `calc(${valueCalculated}%)`;
      } else {
        return `100%`;
      }
    };


    const handleAddToCart = async (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault(); // Prevent page reload

  if (!selectedSize) {
    setSizeError(true);
    return;
  }

  const cart_uuid = localStorage.getItem("cart_uuid");

  try {
    setIsAddingToCart(true);

    if (!cart_uuid) {
      const createCartResponse = await api.post(`${environment.baseUrl}/cart/create`, {});
      localStorage.setItem("cart_uuid", createCartResponse.data.data.cart_uuid);
    }

    const response = await api.post(`${environment.baseUrl}/cart/item/add`, {
      cart_uuid: localStorage.getItem("cart_uuid"),
      campaign_uuid: id,
      quantity: itemCount,
      size: selectedSize,
    });


    // console.log(response.data.data)

    if (response.data.success) {
      
      // toast.success("Added to cart");
      // Set cart popup data from response
      setAddedCartItem({
        image_url: response.data.data.image_url || (campaignImages.length > 0 ? campaignImages[0].media_url : ''),
        name: campaignDetails?.campaign_title || '',
        quantity: response.data.data.quantity || itemCount,
        size: response.data.data.size || selectedSize,
      });
      setShowCartPopup(true);
      
      // Auto-hide popup after 5 seconds
      setTimeout(() => {
        setShowCartPopup(false);
      }, 15000);
    } else {
      toast.error("Failed to add to cart, please try again");
    }
  } catch {
    toast.error("Failed to add to cart, please try again");
  } finally {
    setIsAddingToCart(false);
  }
};

const handleBuyNow = async (event: React.MouseEvent<HTMLButtonElement>) => {
   event.preventDefault();


  if (!selectedSize) {
    setSizeError(true);
    return;
  }

  try {
    setIsBuyingNow(true);
    const cart_uuid = localStorage.getItem("cart_uuid");

    // Create cart if it doesn't exist
    if (!cart_uuid) {
      const createCartResponse = await api.post(`${environment.baseUrl}/cart/create`, {});
      localStorage.setItem("cart_uuid", createCartResponse.data.data.cart_uuid);
    }

    // Add item to cart
    const response = await api.post(`${environment.baseUrl}/cart/item/add`, {
      cart_uuid: localStorage.getItem("cart_uuid"),
      campaign_uuid: id,
      quantity: itemCount,
      size: selectedSize,
    });

    if (response.data.success) {
      // Navigate to checkout page
      navigate("/checkout");
    } else {
      toast.error("Failed to process your order, please try again");
    }
  } catch (error) {
    toast.error("An error occurred while processing your order");
  } finally {
    setIsBuyingNow(false);
  }
}


    // Function to manipulate cloudinary image url
    const getCloudinaryUrl = (
      url: string,
      width: number,
      height: number
    ): string => {
      if (url.includes("cloudinary.com")) {
        return url.replace(
          "/upload/",
          `/upload/w_${width},h_${height},c_fit,ar_1:1,f_auto,q_auto:best,dpr_auto,c_lpad,b_auto:predominant/`
        );
      }
      return url;
    };

    useEffect(() => {
      // Initialize activeUuid with the first image's UUID when campaignImages are loaded
      if (campaignImages.length > 0 && !activeUuid) {
        setActiveUuid(campaignImages[0].uuid);
      }
      TiktokPixel.pageView();
    }, [campaignImages, activeUuid]);

    const handleShare = (platform: string) => {
      const url = window.location.href;
      const text = `Check out this campaign: ${campaignDetails?.campaign_title}`;
      let shareUrl = "";

      switch (platform) {
        case "facebook":
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
          break;
        case "twitter":
          shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
          break;
        case "instagram":
          // Instagram does not support direct URL sharing
          alert("Instagram does not support direct URL sharing.");
          return;
        case "snapchat":
          // Snapchat does not support direct URL sharing
          alert("Snapchat does not support direct URL sharing.");
          return;
        case "tiktok":
          // TikTok does not support direct URL sharing
          alert("TikTok does not support direct URL sharing.");
          return;
        default:
          return;
      }

      window.open(shareUrl, "_blank");
    };

    const repeatedRef = campaignDetails && (
      <div className="relative bg-white w-full h-full flex flex-col lg:flex-row items-start gap-3 justify-between">
        <div className="w-1/5 pr-2 h-[675px] hidden lg:block py-1.5 overflow-y-hidden overflow-x-hidden space-y-3">
          {campaignImages.map((img, index) => {
            const cloudinaryImageUrl = img.media_url.includes("cloudinary.com")
              ? img.media_url.replace(
                  "/upload/",
                  "/upload/w_250,h_250,c_fit,ar_1:1,f_auto,q_auto:best,dpr_auto,c_lpad/"
                )
              : img.media_url;

            return (
              <div
                key={img.uuid}
                className={`border h-12 w-12 2xl:h-14 2xl:w-14 rounded-lg mx-auto  cursor-pointer ${
                  img.uuid === activeUuid ? "ring-1 ring-zinc-700" : ""
                }`}
              >
                <img
                  src={cloudinaryImageUrl}
                  className="object-cover w-full h-full rounded-xl"
                  alt="Campaign image thumbnail"
                  onClick={() => handleThumbnailClick(img.uuid)}
                />
              </div>
            );
          })}
        </div>
        <div className="w-full lg:w-4/5 ">
          <Swiper
            modules={[Navigation]}
            loop={true}
            spaceBetween={5}
            className="single-campaign-carousel"
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            onSlideChange={(swiper: SwiperType) => {
              const realIndex = swiper.realIndex; // Get the real index (ignores the loop duplicates)
              setActiveUuid(campaignImages[realIndex]?.uuid || null);
            }}
            onSwiper={(swiper: SwiperType) => {
              swiperRef.current = swiper;
            }}
          >
            {campaignDetails &&
              campaignImages.map((img) => {
                const cloudinaryImageUrl = img.media_url.includes(
                  "cloudinary.com"
                )
                  ? img.media_url.replace(
                      "/upload/",
                      "/upload/w_1024,h_1536,c_fit,ar_1:1,f_auto,q_auto:best,dpr_auto,c_lpad/"
                    )
                  : img.media_url;

                return (
                  <SwiperSlide key={img.uuid} className="!h-[675px]">
                    <img
                      src={cloudinaryImageUrl}
                      className="object-cover w-full h-full"
                      alt="Campaign image"
                    />
                  </SwiperSlide>
                );
              })}
            <button className="swiper-button-next">
              <FaChevronRight size={10} />
            </button>
            <button className="swiper-button-prev">
              <FaChevronLeft size={10} />
            </button>
          </Swiper>
        </div>

        {/* MOBILE THUMBNAIL */}
        {/* <div className="w-full lg:hidden flex overflow-x-scroll py-1.5 items-center mt-1.5 space-x-3 px-2">
          {campaignImages.map((img) => {
            const cloudinaryImageUrl = img.image_url.includes("cloudinary.com")
              ? img.image_url.replace(
                  "/upload/",
                  "/upload/w_100,h_100,c_fit,ar_1:1,f_auto,q_auto:best,dpr_auto,c_lpad/"
                )
              : img.image_url;

            return (
              <div
                key={img.uuid}
                className={`border h-12 w-12 rounded-lg  cursor-pointer flex-shrink-0 ${
                  img.uuid === activeUuid ? "ring-1 ring-zinc-700" : ""
                }`}
                onClick={() => handleThumbnailClick(img.uuid)}
              >
                <img
                  src={cloudinaryImageUrl}
                  className="object-cover w-full h-full rounded-xl"
                  alt="Campaign image thumbnail"
                />
              </div>
            );
          })}
        </div> */}
      </div>
    );

    // Cart popup component
    const CartPopup = () => {
      if (!showCartPopup || !addedCartItem) return null;
      
      // Get optimized image URL for the popup
      const imageUrl = addedCartItem.image_url.includes("cloudinary.com")
        ? addedCartItem.image_url.replace(
            "/upload/",
            "/upload/w_100,h_100,c_fit,ar_1:1,f_auto,q_auto:best,dpr_auto,c_lpad/"
          )
        : addedCartItem.image_url;
        
      return (
        <div className="fixed top-5 right-5 z-50 bg-white shadow-lg rounded-lg p-4 w-80 cart-popup-animation">
          <div className="flex items-center justify-between mb-2 pb-2 border-b border-gray-200">
            <div className="flex items-center gap-2">
              <FaCheckCircle className="text-green-600 " />
              <span className="font-medium text-green-600">Added to cart</span>
            </div>
            {/* <span className="text-xs text-gray-500">Just now</span> */}
          </div>
          <div className="flex items-start justify-between mb-3">
            <div className="flex items-center gap-3">
              <div className="w-16 h-16 rounded-md overflow-hidden flex-shrink-0">
                <img src={imageUrl} alt="Product" className="w-full h-full object-cover" />
              </div>
              <div className="flex-1">
                <h4 className="font-medium text-sm">{addedCartItem.name}</h4>
                <p className="text-xs text-gray-600 mt-1">
                  Quantity: {addedCartItem.quantity}
                </p>
                <p className="text-xs text-gray-600">
                  Size: {addedCartItem.size}
                </p>
              </div>
            </div>
            <button 
              className="text-gray-400 hover:text-gray-600" 
              onClick={() => setShowCartPopup(false)}
            >
              <FaTimes />
            </button>
          </div>
          <div className="flex flex-col gap-2 mt-3">
            <button 
              className="flex-1 py-2 px-3 border border-gray-300 hover:bg-gray-100 text-sm rounded-full font-medium transition-colors"
              onClick={() => navigate('/cart')}
            >
              View Cart
            </button>
            <button 
              className="flex-1 py-2 px-3 bg-black text-white hover:bg-gray-800 text-sm rounded-full font-medium transition-colors"
              onClick={() => navigate('/checkout')}
            >
              Checkout
            </button>
          </div>
        </div>
      );
    };

    return campaignDetails ? (
      <div className="lg:py-8 py-4 web_padding_x grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-14 2xl:gap-20">
        {/* Cart popup */}
        <CartPopup />
        
        <div className="lg:order-1">
          <h2 className="web_heading_2 bar_above">
            {campaignDetails?.campaign_title}
          </h2>
          <h2 className="web_heading_2 mt-2">{campaignDetails?.product_title}</h2>

          <div className="lg:hidden">{repeatedRef}</div>

          <div className="h-[250px] flex items-center justify-center px-5">
            <div className="relative p-[4px] flex-1 border-2 border-solid  h-10 rounded-[21px]">
              <div
                style={{
                  width: formatBarWidth(),
                }}
                className="relative h-full rounded-[21px] overflow-hidden"
              >
                <img src={BarImg} className="object-cover h-full w-full" />
                <div className="slanted_bars white_slanted_bars h-full w-full top-0 left-0 absolute bg-transparent"></div>
              </div>
              <div className="absolute w-full left-0 h-full top-0">
                <div
                  className="campaign_dotted_line_with_circles"
                  style={{
                    left: `${
                      ((campaignDetails?.production_goal_order_number ?? 0) *
                        100) /
                      (campaignDetails?.end_goal_order_number ?? 0)
                    }%`,
                  }}
                >
                  <span className="dot_above">
                    Production <br /> Goal <br />{" "}
                    {campaignDetails?.production_goal_order_number ?? 0} orders
                  </span>
                  <span className="dot_below">
                    {currencyView}{" "}
                    {currencyFormat(campaignDetails?.production_goal_price) ?? 0}{" "}
                    <br /> P.G Price
                  </span>
                </div>

                <div className="campaign_dotted_line_with_circles right-0">
                  <span className="dot_above">
                    End <br /> Goal <br />{" "}
                    {campaignDetails?.end_goal_order_number ?? 0} orders
                  </span>
                  <span className="dot_below">
                    {currencyView}{" "}
                    {currencyFormat(campaignDetails?.end_goal_price) ?? 0}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between font-bold gap-2">
            <div>
              <div className="text-base md:text-[24px]">
                {currencyView}{" "}
                {currencyFormat(campaignDetails?.current_price) ?? 0}
              </div>
              <div className="text-xs md:text-sm">Current Price</div>
            </div>

            <div className="bg-black w-[2px] h-[50px]"></div>

            <div>
              <div className="text-base md:text-[24px]">{current_orders}</div>
              <div className="text-xs md:text-sm">Current Orders</div>
            </div>

            <div className="bg-black w-[2px] h-[50px]"></div>

            <div>
              <div className="text-base md:text-[24px]">
                {campaignDetails?.endsIn}
              </div>
              <div className="text-xs md:text-sm">Days To Go</div>
            </div>
          </div>
          {/* grid-cols-[auto_1fr] */}
          <form className="mt-10 flex flex-col tall:mt-14 2xl:mt-14   gap-4 sm:gap-6 lg:gap-7 ">
            <div className="flex flex-col gap-2">
              {sizeError && (
                <div className="text-red-500 text-sm">
                  Please select a size before backing the campaign
                </div>
              )}

              {/* Label and Select on the same row */}
              <div className="flex items-center gap-9">
                <div>
                  <div className="sm:text-lg font-bold">Size</div>
                  <div className="text-xs sm:text-sm">(Size Chart)</div>
                </div>

                <Select
                  className="flex-1"
                  classNamePrefix="custom_select"
                  options={sizes}
                  onChange={(e) => {
                    setSelectedSize(e?.value);
                    setSizeError(false);
                  }}
                  placeholder="Select size"
                  value={sizes
                    .flatMap((group) => group.options)
                    .find((option) => option.value === selectedSize)}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: sizeError ? "#ef4444" : base.borderColor,
                      "&:hover": {
                        borderColor: sizeError ? "#ef4444" : base.borderColor,
                      },
                    }),
                  }}
                />
              </div>
            </div>

            <div className="sm:text-lg font-bold flex items-center gap-5">
              <p>Quantity</p>
              <div className="w-[120px]">
                <ItemCountInput
                  value={itemCount}
                  onValueChange={handleItemCountChange}
                />
              </div>
            </div>

            {/* <div className="flex items-center gap-5">
              <button onClick={handleAddToCart} className="flex items-center justify-center gap-3 px-6 py-2 rounded-[24px] w-[100%]   mx-auto text-white bg-black hover:bg-black/90" disabled={isAddingToCart || isBuyingNow}>
                Add to Cart
              </button>
              <button onClick={handleBuyNow} className="flex items-center justify-center gap-3 px-6 py-2 rounded-[24px] w-[100%]   mx-auto text-white bg-black hover:bg-black/90" disabled={isAddingToCart || isBuyingNow}>
                Buy Now
              </button>
            </div> */}
            <div className="col-span-2 space-y-4">
              {/* <button onClick={handleAddToCart} className="flex items-center justify-center gap-3 px-6 py-2 rounded-[24px] w-[100%]   mx-auto text-white bg-black hover:bg-black/90" disabled={isAddingToCart}>
                Add to Cart
              </button> */}
              <button
                type="button"
                onClick={() => {
                  if (!selectedSize) {
                    setSizeError(true);
                    return;
                  }

                  handleAddToCart(event);
                  fbq("trackCustom", "BackCampaign", {
                    campaign_id: campaignDetails?.uuid, // Track the campaign ID
                    amount: campaignDetails?.current_price, // Track the amount
                  });
                  TiktokPixel.track("AddToCart", {
                    content_id: campaignDetails?.uuid,
                    content_name: campaignDetails?.campaign_title,
                    content_type: "product",
                    currency: "USD",
                    value: campaignDetails?.current_price,
                  });

                  // setIsComingSoonModalVisible(true);
                  // console.log("Back campaign clicked!");
                  // setMailchimpPopupVisible(true);
                  sessionStorage.setItem("campaignToBack", id!);
                  sessionStorage.setItem("quantity", itemCount.toString());
                  sessionStorage.setItem("size", selectedSize.toString());
                  sessionStorage.setItem(
                    "amount",
                    campaignDetails!.current_price.toString()
                  );
                  // navigate(
                  //   `/back-campaign/${id}?quantity=${itemCount}&amount=${campaignDetails!.current_price.toString()}&size=${selectedSize}`
                  // );
                }}
                className="px-6 py-2 rounded-[24px] block w-[100%]   mx-auto text-white bg-[#028858] hover:bg-[#015738]"
              >
                Back this Campaign
              </button>

              {mailchimpPopupVisible && (
                <MailchimpCampaginPopup
                  onClose={() => setMailchimpPopupVisible(false)}
                />
              )}
              {/* {isComingSoonModalVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-6 flex  flex-col rounded-lg shadow-lg">
                    <h2 className="text-xl font-bold mb-4 text-center">
                      Coming Soon
                    </h2>
                    <p>This feature is coming soon. Stay tuned!</p>
                    <button
                      onClick={() => setIsComingSoonModalVisible(false)}
                      className="mt-4 px-4 py-2 bg-[#10152B] text-white rounded-full"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )} */}

              {/* <img src={poweredBySttrand} className="block mx-auto mt-3" alt="" /> */}
              <div className="flex justify-between items-center  mt-4 tall:mt-8 2xl:mt-8">
                <div className="flex gap-2 mx-auto items-center">
                  <div
                    className="w-[34px] h-[34px] bg-black rounded-full grid place-content-center cursor-pointer"
                    onClick={() => handleShare("facebook")}
                  >
                    <FaFacebookF className="text-white text-base" />
                  </div>
                  {/* <div
                    className="w-[34px] h-[34px] bg-black rounded-full grid place-content-center cursor-pointer"
                    onClick={() => handleShare("instagram")}
                  >
                    <FaInstagram className="text-white text-base" />
                  </div> */}
                  {/* <div
                    className="w-[34px] h-[34px] bg-black rounded-full grid place-content-center cursor-pointer"
                    onClick={() => handleShare("snapchat")}
                  >
                    <FaSnapchatGhost className="text-white text-base" />
                  </div> */}
                  <div
                    className="w-[34px] h-[34px] bg-black rounded-full grid place-content-center cursor-pointer"
                    onClick={() => handleShare("twitter")}
                  >
                    <FaXTwitter className="text-white text-base" />
                  </div>
                  {/* <div
                    className="w-[34px] h-[34px] bg-black rounded-full grid place-content-center cursor-pointer"
                    onClick={() => handleShare("tiktok")}
                  >
                    <FaTiktok className="text-white text-base" />
                  </div> */}
                </div>
              </div>

              {/* <div className="mt-4 text-center">
                Product and shipping times are 60 Days. This product will go into
                production at the end of the campaign if the Production Goal is
                met by January 31st, 2025 at 11:59pm EST.
              </div> */}
              <div className="mt-4 text-center">
                Production and shipping will take 50 days after the conclusion of
                the campaign. This product will go into production at the end of
                the campaign at least if the Production Goal is met by{" "}
                {moment(campaignDetails?.campaign_deadline).format(
                  "Do, MMMM YYYY"
                )}{" "}
                at 11:59pm EST.
              </div>
            </div>
          </form>
        </div>

        <div>
          <div className="hidden lg:block h-screen max-h-[730px] w-full max-w-[600px] mx-auto">
            {repeatedRef}
          </div>

          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
          >
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => {
                setActiveTab(newValue);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Comments" />
              <Tab label="Campaign" />
              <Tab label="Updates" />
            </Tabs>
          </Box>
          <div className="mt-5">
            {activeTab === 0 && (
              <CampaignSingle_Comments campaignBacked={campaignBacked} />
            )}
            {activeTab === 1 && <CampaignSingle_1 />}
            {activeTab === 2 && <CampaignSingle_Updates />}
          </div>
        </div>
      </div>
    ) : (
      <div className="web_padding_y web_padding_x">
        <Stack spacing={1}>
          <Stack spacing={1} direction={"row"}>
            <Skeleton variant="rounded" width={400} height={200} />
            <Skeleton variant="rounded" width={400} height={200} />
            <Skeleton variant="rounded" width={400} height={200} />
          </Stack>
          <Stack spacing={1} direction={"row"}>
            <Skeleton variant="rounded" width={400} height={200} />
            <Skeleton variant="rounded" width={400} height={200} />
            <Skeleton variant="rounded" width={400} height={200} />
          </Stack>
        </Stack>
      </div>
    );
  };

  export default CampaignSingleNew;
