import { DashboardTitle } from "../../components/DashboardTitle";
import { Skeleton, TextField } from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import api from "../../api/api";
import { environment } from "../../environments/environment";
import React, { useState, useEffect } from "react";
import { BlackButton } from "../../components/black-button";
import { useNavigate } from "react-router-dom";
import { FaSpinner, FaShoppingCart } from "react-icons/fa";
import { set } from "lodash";

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
  'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
  'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const countries = [
  'United States',
  'Canada',
  'Mexico',
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];



const initialValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  address_line1: "",
  address_line2: "",
  city: "",
  state: "",
  zip: "",
  country: "United States",
  phone: "",
  email: "",
};

interface CartItem {
  amount: number;
  campaign_title: string;
  campaign_uuid: string;
  cart_item_uuid: string;
  image_url: string;
  quantity: number;
  size: string;
  title: string;
  unit_price: number;
}

export const CartCheckout = () => {
  const navigate = useNavigate();
  const cart_uuid = localStorage.getItem("cart_uuid");
  const [isLoading, setIsLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState<any>(null);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [cartTotal, setCartTotal] = useState<number>(0);
  const [summaryLoading, setSummaryLoading] = useState(true);

  // Fetch order summary when component mounts
  useEffect(() => {
    const fetchOrderSummary = async () => {
      if (!cart_uuid) return;
      
      try {
        setSummaryLoading(true);
        const response = await api.get(`${environment.baseUrl}/checkout?cart_uuid=${cart_uuid}`);
        setCartItems(response.data.data.cart_items);
        setCartTotal(response.data.data.total_amount);
        setOrderSummary(response.data.data);
      } catch (error) {
        console.error("Error fetching order summary:", error);
        // toast.error("Could not retrieve your order details");
      } finally {
        setSummaryLoading(false);
      }
    };

    fetchOrderSummary();
  }, [cart_uuid]);

    const handleSubmit = async (
      values: any,
      { setSubmitting }: FormikHelpers<any>
    ) => {
      // console.log(values);
      setSubmitting(false);

      const orderPayload = {
        ...values,
        cart_uuid: cart_uuid,
      };

      try {
        setIsLoading(true)
        const response = await api.post(
          `${environment.baseUrl}/campaign/order`, orderPayload)

        // console.log(response.data.data);
        const checkoutLink = response.data.data.payment_link;
        // navigate(checkoutLink);
        // Open the checkout link in a new tab
        window.open(checkoutLink, '_blank');

        localStorage.removeItem("cart_uuid");
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

  return (
    <section className={`${cart_uuid ? "web_padding_x web_padding_y" : ""} !pt-5`}>
      {
        isLoading && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm z-50 flex items-center justify-center transition-all duration-300">
            <div className="bg-white p-8 rounded-2xl shadow-2xl border border-gray-100 w-80 flex flex-col items-center">
              <div className="bg-gray-50 p-4 rounded-full mb-4 relative">
                <FaShoppingCart className="text-4xl text-black opacity-20" />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="h-12 w-12 rounded-full border-4 border-t-black border-r-gray-300 border-b-gray-300 border-l-gray-300 animate-spin"></div>
                </div>
              </div>
              <h3 className="font-bold text-xl mb-1">Processing Order</h3>
              <p className="text-gray-600 text-center">Please wait while we prepare your order...</p>
              <div className="w-full mt-4 bg-gray-100 h-1.5 rounded-full overflow-hidden">
                <div className="h-full bg-black animate-pulse rounded-full"></div>
              </div>
            </div>
          </div>
        )
      }
          <DashboardTitle
            text={"Order Details"}
          ></DashboardTitle>
    
          <div className="grid md:grid-cols-12 gap-8 mt-8">
        {/* Form Section */}
        <div className="md:col-span-8">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ isValid, isSubmitting }) => (
              <Form>
                <div className="dashboard_subtitle md:mt-0">
                  Your Order Information
                </div>
    
                {/* ...existing form fields... */}
                <div className="grid gap-5 mt-4 md:grid-cols-2 xl:grid-cols-3">
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">First Name</h6>
                    <Field
                      name="first_name"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="First Name...."
                    />
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">Middle Name <small> (optional)</small> </h6>
                    <Field
                      name="middle_name"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="Middle Name...."
                    />
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">Last Name </h6>
                    <Field
                      name="last_name"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="Last Name...."
                    />
                  </div>
                </div>
    
    
                <div className="grid gap-5 mt-4 md:grid-cols-2 xl:grid-cols-3">
                <div className="">
                    <h6 className="font-bold text-sm md:text-base">Phone </h6>
                    <Field
                      name="phone"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="Phone Number...."
                    />
                  </div>
                <div className="">
                    <h6 className="font-bold text-sm md:text-base">Email </h6>
                    <Field
                      name="email"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="Email...."
                    />
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">Address Line 1</h6>
                    <Field
                      name="address_line1"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="Street address"
                    />
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">Address Line 2</h6>
                    <Field
                      name="address_line2"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="(optional)"
                    />
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">City</h6>
                    <Field
                      name="city"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="City"
                    />
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">Country</h6>
                    <Field
                      name="country"
                      as="select"
                      className="rounded-full py-2 w-full border mt-3 pl-3"
                      // classNamePrefix="custom_select"
                    >
                      {countries.map((country, index )=> (
                        <option key={index} value={country}>{country}</option>
                      ))}
                    </Field>
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">State</h6>
                    <Field
                      name="state"
                      as="select"
                      className="rounded-full py-2 w-full border mt-3 pl-3"
                      // classNamePrefix="custom_select"
                    >
                      <option value="">Select State</option>
                      {states.map((state, index) => (
                        <option key={index} value={state}>{state}</option>
                      ))}
                    </Field>
                  </div>
                  <div className="">
                    <h6 className="font-bold text-sm md:text-base">ZIP/Postal Code</h6>
                    <Field
                      name="zip"
                      as={TextField}
                      className="admin_input_1 block_input"
                      placeholder="ZIP Code"
                    />
                  </div>
                  
                  
                </div>
    
                <div className="mt-5 w-full max-w-[300px] flex flex-col">
                  <BlackButton
                    text="Proceed to Payment"
                    type="submit"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                  ></BlackButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        
        {/* Order Summary Section */}
        <div className="md:col-span-4">
          <div className="bg-gray-50 rounded-xl p-5 sticky top-5">
            <h2 className="text-lg font-bold mb-4">Order Summary</h2>
            
            {summaryLoading ? (
              <div className="space-y-3">
                <Skeleton variant="rectangular" height={24} />
                <Skeleton variant="rectangular" height={24} />
                <Skeleton variant="rectangular" height={24} />
                <Skeleton variant="rectangular" height={40} />
              </div>
            ) : orderSummary ? (
              <div>
                {/* Items List */}
                <div className="space-y-3 mb-4 max-h-[300px] overflow-y-auto">
                  {/* {orderSummary.items?.map((item: any, index: number) => (
                    <div key={index} className="flex justify-between items-center py-2 border-b border-gray-200">
                      <div className="flex items-center space-x-3">
                        {item.image && (
                          <img 
                            src={item.image} 
                            alt={item.name} 
                            className="w-12 h-12 object-cover rounded"
                          />
                        )}
                        <div>
                          <p className="font-medium">{item.name}</p>
                          <p className="text-sm text-gray-500">Qty: {item.quantity}</p>
                        </div>
                      </div>
                      <p className="font-medium">${Number(item.price).toFixed(2)}</p>
                    </div>
                  ))} */}
                    {cartItems.map((item, index) => (
                      <div key={index} className="flex justify-between items-center py-2 border-b border-gray-200">
                        <div className="flex items-center space-x-3">
                          {item.image_url && (
                            <img 
                              src={item.image_url} 
                              alt={item.title} 
                              className="w-12 h-12 object-cover rounded"
                            />
                          )}
                          <div>
                            <p className="font-medium">{item.title}</p>
                            <p className="text-sm text-gray-500">Qty: {item.quantity}</p>
                          </div>
                        </div>
                        <p className="font-medium">${Number(item.unit_price * item.quantity).toFixed(2)}</p>
                      </div>
                    ))}
                </div>
                
                {/* Totals */}
                <div className="flex justify-between items-center py-2 border-b border-gray-200">
                  <p className="font-medium text-lg">Subtotal</p>
                    <p className="font-bold text-lg">${cartTotal.toFixed(2)}</p>
                    </div>
                  {/* <div className="flex justify-between items-center py-2 border-b border-gray-200">
                    <p className="font-medium">Shipping</p>
                    <p className="font-medium">Free</p>
                    </div> */}
              </div>
            ) : (
              <div className="text-center py-4 text-gray-500">
                No items in your cart
              </div>
            )}
          </div>
        </div>
      </div>
        </section>
  )
}
