import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ICampaignAdmin } from "../../models/admin/campaigns";
import axios from "axios";
import { environment } from "../../environments/environment";
import { FormattedDate, IntlProvider } from "react-intl";
import hamburger from "../../assets/icons/black-hamburger.svg";
import bottle from "../../assets/black-bottle-back.png";
import { Skeleton, Stack } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../api/api";

interface CampaignImagesProps {
  campaign_uuid: string;
  media_url: string;
}

export default function AdminCampaignSearchResult() {
  const { searchQuery } = useParams<{ searchQuery: string }>();
  const [campaigns, setCampaigns] = useState<ICampaignAdmin[] | null>(null);
  const [campaignImages, setCampaignImages] = useState<
    CampaignImagesProps[] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSearchResult = useCallback(async (query: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${environment.baseUrl}/campaign/search?q=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        const campaignImages = response.data.data.campaign_images;

        const extractedImages = Object.values(campaignImages)
          .flat()
          .filter((img: any) => img.media_url)
          .map((img: any) => {
            const cloudinaryImageUrl = img.media_url.includes("cloudinary.com")
              ? img.media_url.replace(
                  "/upload/",
                  "/upload/w_250,h_169,c_fill,f_auto,c_lpad,b_auto:predominant/"
                )
              : img.media_url;

            return {
              campaign_uuid: img.campaign_uuid,
              media_url: cloudinaryImageUrl,
            };
          });

        setCampaignImages(extractedImages);
        setCampaigns(response.data.data?.campaigns || []);
      } else {
        setError("Failed to fetch search results");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred while fetching search results");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // const deleteCampaign = async (uuid: string) => {
  //   try {
  //     const response = await api.delete(
  //       `${environment.baseUrl}/admin/campaign/delete/${uuid}`
  //     );
  //     if (response.data.success) {
  //             toast.success(`${response.data.message}`);
  //     }
  //     // toast.success("Campaign deleted successfully");
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("Failed to delete campaign");
  //   }
  // }


  useEffect(() => {
    if (searchQuery) {
      fetchSearchResult(searchQuery);
    }
  }, [searchQuery, fetchSearchResult]);

  if (!searchQuery) {
    return <div>No search query provided</div>;
  }

  return (
    <section className="font-montserrat">
      <IntlProvider locale="en">
        <div className="flex items-center flex-wrap gap-6 justify-between">
          <div className="flex items-center gap-3">
            <img src={hamburger} alt="campaigns-icon" className="w-[18px]" />
            <h5 className="text-[22px] font-bold">
              SEARCH RESULTS FOR &ldquo;{searchQuery}&rdquo;
            </h5>
          </div>
        </div>

        <div className="mt-7 bg-white text-black p-4 md:p-8 tall:py-[60px] xl:px-9 rounded-[12px] md:rounded-[18px]">
          {isLoading ? (
            // Show loading skeleton while fetching data
            <Stack spacing={1}>
              <Stack spacing={1} direction={"row"}>
                <Skeleton variant="rounded" width={210} height={60} />
                <Skeleton variant="rounded" width={410} height={60} />
                <Skeleton variant="rounded" width={1100} height={60} />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <Skeleton variant="rounded" width={210} height={60} />
                <Skeleton variant="rounded" width={410} height={60} />
                <Skeleton variant="rounded" width={1100} height={60} />
              </Stack>
            </Stack>
          ) : campaigns && campaigns.length > 0 ? (
            // Show campaign results if found
            <>
              {campaigns.map((campaign, index) => {
                const campaignImage = campaignImages?.find(
                  (img) => img.campaign_uuid === campaign.uuid
                );

                return (
                  <div
                    key={campaign.uuid}
                    className={`grid gap-6 lg:grid-cols-[250px_auto_1fr] xl:gap-10  ${
                      index === 0 ? "" : " mt-5"
                    }`}
                  >
                    <div className="aspect-[245/165]">
                      <Link to={`/admin/campaigns/${campaign.uuid}`}>
                        <img
                          src={campaignImage?.media_url || bottle}
                          className="w-full h-full object-cover object-top"
                          alt=""
                        />
                      </Link>
                    </div>

                    <div>
                      <h4 className="font-bold text-[22px]">
                        {campaign.campaign_title}
                      </h4>
                      <p className="font-bold text-sm mt-2">250% funded</p>
                      <p className="text-sm mt-2">By Robyn</p>

                      <div className="mt-2 flex gap-4">
                        <Link to={`/admin/campaigns/${campaign.uuid}`}>
                          <span className="underline font-medium hover:font-bold text-sm">
                            View
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div className="overflow-x-auto">
                      <table className="w-full whitespace-nowrap">
                        <thead>
                          <tr>
                            <th className="text-left font-normal pb-7">
                              <FormattedDate
                                value={campaign.CreatedAt}
                                year="numeric"
                                month="short"
                                day="2-digit"
                              />
                            </th>
                            <th className="text-left pl-5 font-normal pb-7">
                              LOCATION
                            </th>
                            <th className="text-left pl-5 font-normal pb-7">
                              STATUS
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="border-t border-solid border-t-black">
                            <td className="pt-7">FEB 20, 2023</td>
                            <td className="pt-7 pl-5">{campaign.location}</td>
                            <td className="pt-7 pl-5">
                              <div className="py-1 px-5 text-center w-fit mr-auto text-sm leading-[14px] rounded-[13px] bg-black text-white">
                                ACTIVE
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            // Show message when no campaigns are found
            <div className="text-center mt-10">
              <p className="text-gray-600 text-lg">
                No campaigns found for "{searchQuery}".
              </p>
              <p className="text-gray-500 mt-2">
                Try searching with different keywords.
              </p>
            </div>
          )}
        </div>
      </IntlProvider>
    </section>
  );
}
