import React, { useState, useEffect } from "react";
import { BlackButton } from "../../components/black-button";
import api from "../../api/api";
import { environment } from "../../environments/environment";
import { Link, useNavigate } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";

// Add CartItem interface for type safety
interface CartItem {
  amount: number;
  campaign_title: string;
  campaign_uuid: string;
  cart_item_uuid: string;
  image_url: string;
  quantity: number;
  size: string;
  title: string;
  unit_price: number;
}

export const Cart = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [cartTotal, setCartTotal] = useState<number>(0);

  const fetchCartItems = async () => {
    const cart_uuid = localStorage.getItem("cart_uuid");

    if (cart_uuid) {
      try {
        const response = await api.get(`${environment.baseUrl}/checkout?cart_uuid=${cart_uuid}`)

        // Handle case when API response doesn't include cart items or data is malformed
        if (response.data?.data) {
          // Set cart items, defaulting to empty array if none exist
          const items = response.data.data.cart_items || [];
          setCartItems(items);
          
          // Set total amount, defaulting to 0 if it doesn't exist
          const total = response.data.data.total_amount || 0;
          setCartTotal(total);
        } else {
          // Handle missing data in response
          console.error("Invalid response format from API");
          setCartItems([]);
          setCartTotal(0);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setCartItems([]);
        setCartTotal(0);
      }
    } else {
      // No cart UUID found in localStorage
      setCartItems([]);
      setCartTotal(0);
    }
  }

  // Add useEffect to fetch cart items when component mounts
  useEffect(() => {
    fetchCartItems();
  }, []);

  // Function to handle item removal
  const handleRemoveItem = async (cartItemId: string) => {
    const cart_uuid = localStorage.getItem("cart_uuid");
    if (cart_uuid) {
      try {
        const response = await api.delete(`${environment.baseUrl}/cart/item/delete?cart_uuid=${cart_uuid}&cart_item_uuid=${cartItemId}`);
        // Refresh cart after removing item
        fetchCartItems();
        // window.location.reload();

        // console.log(response);
      } catch (error) {
        console.error("Error removing item:", error);
      }
    }
  };

  // Function to update item quantity
  const handleQuantityChange = async (itemId: string, quantity: number) => {
    const cart_uuid = localStorage.getItem("cart_uuid");
    if (cart_uuid) {
      try {
        await api.put(`${environment.baseUrl}/cart/items/${itemId}?cart_uuid=${cart_uuid}`, {
          quantity
        });
        // Refresh cart after updating quantity
        fetchCartItems();
      } catch (error) {
        console.error("Error updating quantity:", error);
      }
    }
  };

  // Function to handle clearing the entire cart
  const handleClearCart = async () => {
    const cart_uuid = localStorage.getItem("cart_uuid");
    if (cart_uuid && cartItems.length > 0) {
      if (window.confirm("Are you sure you want to clear your entire cart?")) {
        try {
          await api.delete(`${environment.baseUrl}/cart/delete?cart_uuid=${cart_uuid}`);
          // Reset cart state
          setCartItems([]);
          setCartTotal(0);
          localStorage.removeItem("cart_uuid");
        } catch (error) {
          console.error("Error clearing cart:", error);
        }
      }
    }
  };

  return (
    <>
      <main className="web_padding_x web_padding_y max-w-[2000px]">
        <h2 className="web_heading bar_above">Your Cart</h2>

        <div className="grid mt-8 gap-7 xl:grid-cols-[65%_35%] items-start">
          <div className="rounded-[20px] border border-solid border-[#EBEBEB] p-8">
            <div className="flex justify-between items-center">
              <div className="font-bold text-lg">( {cartItems.length} items )</div>
              {cartItems.length > 0 && (
                <button 
                  onClick={handleClearCart}
                  className="px-4 py-2 rounded-md text-red-500 border border-red-500 hover:bg-red-50 transition-colors"
                >
                  Clear Cart
                </button>
              )}
            </div>

            <div className="mt-10 flex flex-col gap-10">
              {cartItems.length > 0 ? (
                cartItems.map((item, index) => (
                  <div key={index} className="relative flex flex-col md:flex-row gap-2 md:gap-5 items-center border-b pb-6 last:border-b-0">
                    {/* Trash icon positioned at top right corner */}
                    <button 
                      className="absolute top-0 right-0 p-2 rounded-full hover:bg-gray-100 transition-colors"
                      onClick={() => handleRemoveItem(item.cart_item_uuid)}
                      aria-label="Remove item"
                    >
                      <span className="text-red-500">
                        {React.createElement(FiTrash2, { size: 20 })}
                      </span>
                    </button>
                    
                    <div className="w-[132px] h-[196px] rounded-[27px] bg-[#EBEBEB] overflow-hidden">
                      {item.image_url && (
                        <img 
                          src={item.image_url} 
                          alt={item.title} 
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                    <div className="flex flex-col md:self-start flex-grow">
                      <h4 className="font-bold text-xl pr-8">
                        
                        {/* {item.title} */}

                        <Link to={`/campaigns/${item.campaign_uuid}`}>{item.campaign_title}</Link>
                      </h4>
                      {item.size && <p className="text-gray-500">Size: {item.size}</p>}
                      <p className="text-gray-500">Campaign: {item.campaign_title}</p>
                      <p className="font-medium mt-1">Quantity: {item.quantity}</p>
                    </div>
                    <div className="md:self-end mt-2 md:mt-0">
                      <div className="text-center md:text-right font-bold text-[30px] sm:text-[37px]">
                        $ {(item.unit_price * item.quantity).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center py-8">Your cart is empty.</p>
              )}
            </div>
          </div>

          <div className="rounded-[20px] border border-solid border-[#EBEBEB] pt-8 pb-6">
            <BlackButton
              onClick={() => navigate("/checkout")}
              text="Proceed to checkout"
              className={`block mx-auto w-[80%] md:w-[70%] ${cartItems.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
              isDisabled={cartItems.length === 0}
            ></BlackButton>

            <div className="flex justify-between pt-4 pb-2 px-6 md:px-12 font-bold">
              <div>Subtotal ({cartItems.length} items)</div>
              <div>$ {cartTotal.toFixed(2)}</div>
            </div>
            {/* <div className="flex justify-between border-t border-t-[#EBEBEB] border-solid pt-4 pb-2 px-6 md:px-12 font-bold">
              <div>Taxes</div>
              <div>Calculated at Checkout</div>
            </div> */}
            <div className="mx-3 h-[1px] bg-[#EBEBEB] mt-2"></div>
            <div className="flex justify-between pt-4 pb-2 px-6 md:px-12 font-bold">
              <div>Estimated Total</div>
              <div>$ {cartTotal.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
