import logo from '../assets/logo.svg';
import React, { useRef, useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./website-navbar.css";
import { VscMenu, VscClose } from "react-icons/vsc";
import styled from "styled-components";
import { FiSearch, FiShoppingCart } from "react-icons/fi";
import "@szhsin/react-menu/dist/index.css";
import { BlackButton } from "./black-button";
import { useSelector } from "react-redux";
import { IAppState } from "../store/store";
import { NavbarUserIcon } from "./navbar-components";
import whiteLogo from "../assets/logo-white.png";
import api from '../api/api';
import { environment } from '../environments/environment';

// Add CartItem interface for type safety
interface CartItem {
  amount: number;
  campaign_title: string;
  campaign_uuid: string;
  image_url: string;
  quantity: number;
  size: string;
  title: string;
  unit_price: number;
}

export const WebsiteHeader = () => {
  const isLoggedIn = useSelector((state: IAppState) => state.auth.token);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const linksRef = useRef<HTMLUListElement>(null);
  const linksContainerRef = useRef<HTMLDivElement>(null);
  const [isNavOpen, setNav] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
  const closeNav = () => setNav(false);
  const navigate = useNavigate();

  // Add ref for tracking the dropdown timeout
  const dropdownTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Add refs for managing dropdown behavior
  const cartButtonRef = useRef<HTMLButtonElement>(null);
  const cartDropdownRef = useRef<HTMLDivElement>(null);

  // Handle mouse events for cart dropdown
  const handleCartMouseEnter = () => {
    // Clear any existing timeout
    if (dropdownTimeoutRef.current) {
      clearTimeout(dropdownTimeoutRef.current);
      dropdownTimeoutRef.current = null;
    }
    
    setIsCartDropdownOpen(true);
  };

  const handleCartMouseLeave = (e: React.MouseEvent) => {
    // Check if the mouse is moving to the dropdown
    if (cartDropdownRef.current && cartDropdownRef.current.contains(e.relatedTarget as Node)) {
      return; // Don't close if moving to the dropdown
    }
    
    setIsCartDropdownOpen(false);
  };
  
  // Add handler for dropdown mouse leave
  const handleDropdownMouseLeave = (e: React.MouseEvent) => {
    // Check if the mouse is moving to the cart button
    if (cartButtonRef.current && cartButtonRef.current.contains(e.relatedTarget as Node)) {
      return; // Don't close if moving to the cart button
    }
    
    setIsCartDropdownOpen(false);
  };

  // Cleanup timeouts when component unmounts
  useEffect(() => {
    return () => {
      if (dropdownTimeoutRef.current) {
        clearTimeout(dropdownTimeoutRef.current);
      }
    };
  }, []);

  // Calculate total cart amount
  const getTotalCartAmount = () => {
    return cartItems.reduce((total, item) => total + item.amount, 0);
  };

  useEffect(() => {
    if (linksRef.current && linksContainerRef.current) {
      if (isNavOpen) {
        linksContainerRef.current.style.right = `0`;
      } else {
        linksContainerRef.current.style.right = `-${500}px`;
      }
    }
  }, [isNavOpen]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        toggleRef.current?.contains(event.target as Node) ||
        toggleRef.current?.childNodes[0]?.contains(event.target as Node)
      ) {
        setNav(!isNavOpen);
        return;
      }

      if (
        isNavOpen &&
        !linksContainerRef?.current?.contains(event.target as Node)
      ) {
        closeNav();
        return;
      }
    };
    const handleScroll = () => {
      if (isNavOpen) {
        closeNav();
      }
    };
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isNavOpen]);

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    closeNav();
    setSearchQuery("");
    navigate(`/campaign-search/${searchQuery}`);
  };

  const createCart = async () => {
    try {
      const createCartResponse = await api.post(`${environment.baseUrl}/cart/create`, {});
      localStorage.setItem("cart_uuid", createCartResponse.data.data.cart_uuid);
    } catch (error) {
      console.error(error);
    }
  }



  useEffect(() => {
    const timer = setInterval(() => {
      const cart_uuid = localStorage.getItem("cart_uuid");
      if (!cart_uuid) {
        createCart();
      } else {
        fetchCartItems();
      }
    }, 2000);
    
    return () => clearInterval(timer);
  },  [])

  // useEffect(() => {
  //   fetchCartItems();
  // }, [])




  const fetchCartItems = async () => {
    const cart_uuid = localStorage.getItem("cart_uuid");

    if (cart_uuid) {
      try {
        const response = await api.get(`${environment.baseUrl}/checkout?cart_uuid=${cart_uuid}`)

        // console.log(response.data.data)
        setCartItems(response.data.data.cart_items || []);
        // console.log(response.data.data.cart_items)
      } catch (error) {
        console.error(error);
      }
    } else {
      createCart();
    }
  }



  

  return (
    <Wrapper isNavOpen={isNavOpen}>
      <nav className="max-container md:px-16 Navbar pt-8 pb-6 flex gap-8 2xl:gap-10 items-center whitespace-nowrap overflow-hidden">
        <NavLink to="/">
          <img src={logo} className="logo w-[180px]" alt="LOGO" />
        </NavLink>

        <form onSubmit={handleSubmitSearch}>
          <div className="relative flex items-center justify-between xl:ml-10 border-2 border-black border-solid rounded-[21px] pl-10 pr-4 h-[41px]">
            <FiSearch className="absolute center_y left-4 text-2xl" />
            <input
              type="input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="outline-none ml-3"
            />
            {/* <Link to={`/campaign-search/${searchQuery}`}>Search</Link> */}
            <button type="submit">Search</button>
          </div>
        </form>
        <NavLink to="/about">About us</NavLink>
        <NavLink to="/how-it-works">How it works</NavLink>
        <NavLink to="/campaigns">Shop</NavLink>
        <NavLink to="/gallery">Gallery</NavLink>
        <NavLink to="/campaigns">Campaigns</NavLink>

        {!isLoggedIn ? (
          <NavLink to="/login">
            <BlackButton text="Login" />
          </NavLink>
        ) : null}

        <div className="cart-container relative">
          <NavLink to="/cart">
            <button className="relative top-[5px]" ref={cartButtonRef}>
              <div className="relative">
                {cartItems.length > 0 && (
                  <span className="absolute -top-2 -right-2 bg-black text-white rounded-full w-5 h-5 flex items-center justify-center text-sm font-bold">
                    {cartItems.length}
                  </span>
                )}
              </div>
              <FiShoppingCart size={24} fontWeight={700} />
            </button>
          </NavLink>
          
          
        </div>

        <NavbarUserIcon />
      </nav>

      <div className="mobile-container">
        <nav className="mobile">
          <NavLink to="/">
            <img src={logo} className="w-[100px]" alt="LOGO" />
          </NavLink>

          <div className="flex gap-3 sm:gap-5 items-center">
            {!isLoggedIn ? (
              <NavLink to="/login">
                <button className="bg-black px-4 py-[6px] rounded-[20px] text-sm text-white">
                  Login
                </button>
              </NavLink>
            ) : null}

            {/* <NavLink to="#">
              <button className="relative top-1">
                <FiShoppingCart size={20} fontWeight={700} />
              </button>
            </NavLink> */}
            <NavLink to="/cart">
              <button className="relative top-1"
                ref={cartButtonRef}>
              <div className="relative">
                {cartItems.length > 0 && (
                  <span className="absolute -top-2 -right-2 bg-black text-white rounded-full w-5 h-5 flex items-center justify-center text-sm font-bold">
                    {cartItems.length}
                  </span>
                )}
              </div>
                <FiShoppingCart size={20} fontWeight={700} />
              </button>
            </NavLink>

            <NavbarUserIcon />

            <button className="toggle" ref={toggleRef}>
              {isNavOpen ? (
                <VscClose size={20} fontWeight={700} />
              ) : (
                <VscMenu size={20} fontWeight={700} />
              )}
            </button>
          </div>
        </nav>
        <div
          className="bg-black text-white p-5 pl-6 fixed top-0 duration-700 w-[287px] h-screen [z-index:100000]"
          ref={linksContainerRef}
        >
          <div className="flex items-center justify-between">
            <img src={whiteLogo} className="w-[90px]" alt="" />
            <button className="toggle" onClick={closeNav}>
              <VscClose size={20} fontWeight={"bold"} />
            </button>
          </div>

          {/* <div className="relative mt-7">
            <FiSearch className="absolute center_y left-4" size={18} />
            <input
              type="text"
              className="outline-none border-2 border-white bg-black text-white border-solid rounded-[21px] pl-10 pr-4 h-[41px]"
            />
          </div> */}
          <form onSubmit={handleSubmitSearch} className="mt-7">
            <div className="relative flex items-center justify-between xl:ml-10 border-2 border-white bg-black border-solid rounded-[21px] pl-0.5 pr-4 h-[41px]">
              <FiSearch className="absolute center_y left-1 text-2xl" />
              <input
                type="input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="outline-none ml-2 pl-5 bg-inherit "
              />
              <button type="submit" className="text-white absolute right-4">
                Search
              </button>
            </div>
          </form>

          <ul ref={linksRef} className="flex flex-col gap-7 mt-6">
            <li>
              <NavLink to="/about" onClick={closeNav}>
                About us
              </NavLink>
            </li>
            <li>
              <NavLink to="/how-it-works" onClick={closeNav}>
                How it works
              </NavLink>
            </li>
            <li>
              <NavLink to="/campaigns" onClick={closeNav}>
                Shop
              </NavLink>
            </li>
            <li>
              <NavLink to="/gallery" onClick={closeNav}>
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink to="/campaigns" onClick={closeNav}>
                Campaigns
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      {/* Cart Dropdown - Disabled
          {isCartDropdownOpen && cartItems.length > 0 && (
            <CartDropdown 
              ref={cartDropdownRef}
              onMouseEnter={handleCartMouseEnter}
              onMouseLeave={handleDropdownMouseLeave}
            >
              <h3 className="dropdown-title">Shopping Cart ({cartItems.length})</h3>
              <div className="cart-items">
                {cartItems.slice(0, 5).map((item, index) => (
                  <div key={index} className="cart-item">
                    <div className="item-image">
                      {item.image_url ? (
                        <img src={item.image_url} alt={item.title} />
                      ) : (
                        <div className="placeholder-image"></div>
                      )}
                    </div>
                    <div className="item-details">
                      <h4>{item.title}</h4>
                      <p>Size: {item.size}</p>
                      <p>Qty: {item.quantity}</p>
                      <p className="price">${item.amount}</p>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="cart-totals">
                <div className="subtotal">
                  <span>Subtotal:</span>
                  <span>${getTotalCartAmount()}</span>
                </div>
              </div>
              
              <div className="dropdown-actions">
                {cartItems.length > 5 && (
                  <button 
                    className="show-more"
                    onClick={() => navigate('/cart')}
                  >
                    Show more ({cartItems.length - 5} more items)
                  </button>
                )}
                <button 
                  className="proceed-btn"
                  onClick={() => navigate('/checkout')}
                >
                  Proceed to checkout
                </button>
              </div>
            </CartDropdown>
          )} */}
    </Wrapper>
  );
};

// Add new styled component for the cart dropdown
const CartDropdown = styled.div.attrs((props: any) => ({
  onMouseEnter: props.onMouseEnter,
  onMouseLeave: props.onMouseLeave
}))`
  position: absolute;
  top: 80px;
  right: 0;
  width: 320px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100000 !important;
  padding: 16px;
  
  .dropdown-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .cart-items {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .cart-item {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
    
    &:last-child {
      border-bottom: none;
    }
  }
  
  .item-image {
    width: 60px;
    height: 60px;
    margin-right: 12px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
    
    .placeholder-image {
      width: 100%;
      height: 100%;
      background-color: #f0f0f0;
      border-radius: 4px;
    }
  }
  
  .item-details {
    flex: 1;
    
    h4 {
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 4px 0;
    }
    
    p {
      font-size: 12px;
      margin: 2px 0;
      color: #666;
    }
    
    .price {
      font-weight: 600;
      color: #000;
    }
  }
  
  .cart-totals {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e0e0e0;
    
    .subtotal {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 14px;
    }
  }
  
  .dropdown-actions {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    button {
      border: none;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      transition: background-color 0.2s;
    }
    
    .show-more {
      background-color: transparent;
      color: #333;
      text-decoration: underline;
      text-align: center;
      
      &:hover {
        color: #000;
      }
    }
    
    .proceed-btn {
      background-color: black;
      color: white;
      
      &:hover {
        background-color: #333;
      }
    }
  }
`;

interface WrapperProps {
  isNavOpen: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  nav:not(.mobile) {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  nav.gap-8 {
    @media screen and (min-width: 961px) and (max-width: 1078px) {
      img.logo {
        width: 150px;
      }
      gap: 1rem;
    }
    @media screen and (min-width: 1079px) and (max-width: 1187px) {
      gap: 1.2rem;
    }
  }
  .mobile-container {
    @media screen and (min-width: 961px) {
      display: none;
    }

    padding: 2em 0;
    position: relative;
  }
  .mobile {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 1rem; */
    svg {
      font-size: 2rem;
    }
  }
  .search-input {
    img {
      top: 15%;
      left: 2%;
    }
    input {
      width: 100%;
      max-width: 600px;
    }
  }
  li.flex {
    /* justify-content: space-between; */
    gap: 3em;
    align-items: center;
  }
  .cart-container {
    position: relative;
  }
`;
